import { CloseOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import React from "react";
import { GalleryImageType } from "../../utils/types";
import Image from "../ui/Image";

const StyledModal = styled.div`
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 11; /* Antd Affix is Z-index = 10*/
  justify-content: center;
  align-items: center;
`;

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  background: black;
  opacity: 0.4;
`;

const Content = styled.div`
  display: flex;
  background: white;
  justify-content: center;
  padding: 1rem;
  height: 80vh;
  width: 80vw;
  z-index: 1;
  position: relative;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const Info = styled.div`
  max-width: 600px;
  margin: 3rem;
`;

const CloseButton = styled(CloseOutlined)`
  position: absolute;
  right: 0;
  top: 0;
  font-size: 20px;
  margin: 3rem;
`;

interface Props {
  currentImage: GalleryImageType;
  visible?: boolean;
  onClose?: () => void;
}

const ImageModal: React.FC<Props> = ({ currentImage, visible, onClose }) => {
  if (!visible) return null;

  const { title, description, fixed, fluid } = currentImage;

  return (
    <StyledModal>
      <Overlay onClick={onClose} />
      <Content>
        <CloseButton onClick={onClose} />
        <Image
          src={fixed.src}
          placeholder={fluid.src}
          style={{ width: "100%", height: "calc(80vh - 2rem)" }} // Modal height - 2x padding
        />
        <Info>
          <h2>{title}</h2>
          <p>{description}</p>
        </Info>
      </Content>
    </StyledModal>
  );
};

export default ImageModal;
