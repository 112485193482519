import styled from "@emotion/styled";
import { Col, Row } from "antd";
import { graphql } from "gatsby";
import { find } from "lodash";
import React, { useState } from "react";
import Layout from "../components/Layout";
import Image from "../components/ui/Image";
import ImageModal from "../components/ui/ImageModal";
import PageTitle from "../components/ui/PageTitle";
import { GalleryImageType, PageType } from "../utils/types";

const GalleryImageWrapper = styled(Col)`
  cursor: pointer;
  border: 2px solid transparent;
  transition: border 0.3s ease;
  &:hover {
    border-color: #333;
  }
  @media (max-width: 1000px) {
    border-width: 1px;
  }
`;

const GalleryImage = ({ image, onClick }) => {
  return (
    <GalleryImageWrapper span={8} onClick={onClick}>
      <Image
        src={image.fixed.src}
        placeholder={image.fluid.src}
        style={{ width: "100%", height: "calc(33.33vw - 10px)" }} // height = 1/3 width - 2x gutter (5px)
      />
    </GalleryImageWrapper>
  );
};

const GalleryPage: React.FC<{ data: any }> = ({ data }) => {
  const page: PageType | undefined =
    data?.allContentfulGalleryPage?.edges[0].node;

  if (!page) return null;

  const { title, images } = page;

  if (!images) return null;

  const [currentImageId, setCurrentImageId] = useState<string>(images[0].id);
  const [showModal, setShowModal] = useState<boolean>(false);

  const currentImage = find(images, { id: currentImageId }) as GalleryImageType;

  const handleSelect = (id: string) => {
    setCurrentImageId(id);
    setShowModal(true);
  };

  return (
    <Layout>
      <ImageModal
        currentImage={currentImage}
        visible={showModal}
        onClose={() => setShowModal(false)}
      />
      <PageTitle>{title}</PageTitle>
      <Row justify="center">
        {images.map((image) => (
          <GalleryImage
            key={image.id}
            image={image}
            onClick={() => handleSelect(image.id)}
          />
        ))}
      </Row>
    </Layout>
  );
};

export default GalleryPage;

export const query = graphql`
  query GalleryPageQuery($id: String!) {
    allContentfulGalleryPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          title
          images {
            id
            title
            description
            fixed(width: 1400, quality: 40) {
              src
            }
            fluid(maxWidth: 120, quality: 10) {
              src
            }
          }
          thumbnail {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
